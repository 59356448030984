<template>
  <section>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div class="text-left">
        <div  class="form-item form-half">
          <div
            class="flex-col text-left">
            <span>Number of Named Driver(s)</span>
            <span>記名司機數量</span>
          </div>
          <el-form-item
            :show-message="false"
            :rules="rules.select"
          >
            <v-radio-group
              v-model="formData.number_of_drivers"
              flex
              border
              :list="driverNumOption"
              @change="handleChangeDriver">

            </v-radio-group>
          </el-form-item>
        </div>
        <div
          v-for="(item,index) in formData.driver"
          :key="index"
          class="dirver-line"
        >
          <div class="flex-col text-left driver-text-title">
            <span>Driver {{index+1}}</span>
            <span>司機 {{index+1}}</span>
          </div>
          <div  class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Relationship with Policyholder</span>
              <span>與投保人關係</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.relationship_id`"
              :prop="`driver.${index}.relationship_id`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-select
                v-model="item.relationship_id"
                :disabled="item.disabled||isOneOfDriver&&index==0"
                :label-key="'name1'"
                :list="relationship_arr(index)"></v-select>
            </el-form-item>
          </div>
          <template v-if="isOneOfDriver&&index!=0||isCompany||!isOneOfDriver">
            <div  class="form-item form-half">
              <div
                class="flex-col text-left">
                <span>Title</span>
                <span>稱謂</span>
              </div>
              <el-form-item
                :ref="`driver.${index}.title`"
                :prop="`driver.${index}.title`"
                :show-message="false"
                :rules="rules.select"
              >
                <v-radio-group
                  v-model="item.title"
                  :disabled="item.disabled"
                  block
                  border
                  :list="titleOption"
                ></v-radio-group>
              </el-form-item>
            </div>
            <div  class="form-item form-half">
              <div
                class="flex-col text-left">
                <span>Last Name</span>
                <span>姓氏</span>
              </div>
              <el-form-item
                :ref="`driver.${index}.last_name`"
                :prop="`driver.${index}.last_name`"
                :show-message="false"
                :rules="rules.last_name"
              >
                <v-input
                  v-model="item.last_name"
                  :disabled="item.disabled"
                ></v-input>
              </el-form-item>
            </div>
            <div  class="form-item form-half">
              <div
                class="flex-col text-left">
                <span>First Name</span>
                <span>名字</span>
              </div>
              <el-form-item
                :ref="`driver.${index}.first_name`"
                :prop="`driver.${index}.first_name`"
                :rules="rules.first_name"
              >
                <v-input
                  v-model="item.first_name"
                  :disabled="item.disabled"
                ></v-input>
              </el-form-item>
            </div>
            <div  class="form-item form-half">
              <div class="flex-sb flex-end">
                <div
                  class="flex-col text-left">
                  <span>Date of Birth</span>
                  <span>出生日期</span>
                </div>
                <span>{{effective_age(formData.effective_date,item.birth)}} yrs old</span>
              </div>
              <el-form-item
                :ref="`driver.${index}.birth`"
                :prop="`driver.${index}.birth`"
                :show-message="false"
                :rules="rules.select"
              >
                <v-birth-day
                  v-model="item.birth"
                  :disabled="item.disabled"
                ></v-birth-day>
              </el-form-item>
            </div>
            <div  class="form-item form-half">
              <!--            <div-->
              <!--              class="flex-col text-left">-->
              <!--              <span>{{item.id_type==1?'HKID Number':'Passport Number'}}</span>-->
              <!--              <span>{{item.id_type==1?'香港身份證號碼':'護照號碼'}}</span>-->
              <!--            </div>-->
              <el-form-item
                :ref="`driver.${index}.id_type`"
                :prop="`driver.${index}.id_type`"
                :show-message="false"
                :rules="rules.select"
              >
                <v-radio-group
                  v-model="item.id_type"
                  flex
                  :list="personalIdTypOptions">
                </v-radio-group>
              </el-form-item>
              <el-form-item
                :ref="item.id_type==1?`driver.${index}.id_no`:`driver.${index}.pp_no`"
                :prop="item.id_type==1?`driver.${index}.id_no`:`driver.${index}.pp_no`"
                :show-message="false"
                :rules="item.id_type==1?rules.id_no:rules.input"
              >
                <v-hkid-input
                  v-if="item.id_type!=2"
                  v-model="item.id_no"
                ></v-hkid-input>
                <v-input
                  v-else
                  v-model="item.pp_no"
                >
                </v-input>
              </el-form-item>
            </div>
            <div  class="form-item form-half">
              <div
                class="flex-col text-left">
                <span>Occupation</span>
                <span>職業</span>
              </div>
              <el-form-item
                :ref="`driver.${index}.occ_id`"
                :prop="`driver.${index}.occ_id`"
                :show-message="false"
                :rules="rules.select"
              >
                <v-select
                  v-model="item.occ_id"
                  :disabled="item.disabled"
                  :label-key="'name1'"
                  :list="globalOptions.occ"></v-select>
              </el-form-item>
              <el-form-item
                v-if="item.occ_id==70"
                :ref="`driver.${index}.occ`"
                :prop="`driver.${index}.occ`"
                :show-message="false"
                :rules="rules.input"
              >
                <v-input
                  v-model="item.occ"
                ></v-input>
              </el-form-item>
            </div>
          </template>
          <div  class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Type of Motorcycle Driving licence</span>
              <span>電單車駕駛執照類型</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.driving_licence_id`"
              :prop="`driver.${index}.driving_licence_id`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-select
                v-model="item.driving_licence_id"
                :disabled="item.disabled"
                :label-key="'name1'"
                :list="globalOptions.driving_licence"></v-select>
            </el-form-item>
          </div>
          <div
            v-if="item.driving_licence_id==3"
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Year of driving Experience</span>
              <span>駕駛年數</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.driver_experience_id`"
              :prop="`driver.${index}.driver_experience_id`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-select
                v-model="item.driver_experience_id"
                :list="dirverExpList"></v-select>
            </el-form-item>
          </div>
          <div  class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Number of driving offence point in last 3 years (Excludes traffic accidents)</span>
              <span>過往3年內被扣分數 (不包括交通意外)</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.deducted_points`"
              :prop="`driver.${index}.deducted_points`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-select
                v-model="item.deducted_points"
                :disabled="item.disabled"
                :list="globalOptions.deducted_points"></v-select>
            </el-form-item>
          </div>
          <div
            v-if="item.deducted_points!=0"
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Reason</span>
              <span>原因</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.deducted_point_reason_id`"
              :prop="`driver.${index}.deducted_point_reason_id`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-checkbox-group
                v-model="item.deducted_point_reason_id"
                :list="globalOptions.deducted_point_reason"
                block
                :label-key="'name1'"
              ></v-checkbox-group>
            </el-form-item>
            <el-form-item
              v-if="item.deducted_point_reason_id&&item.deducted_point_reason_id.includes(4)"
              :ref="`driver.${index}.deducted_point_reason`"
              :prop="`driver.${index}.deducted_point_reason`"
              :show-message="false"
              :rules="rules.input"
            >
              <v-input
                v-model="item.deducted_point_reason"
                type="textarea"
                :rows="4"
              ></v-input>
            </el-form-item>
          </div>
          <div  class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Have any been involved in any car accident or motor claim in the past 3 years?</span>
              <span>是否最近三年曾發生意外、賠償或停牌？</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.has_accident`"
              :prop="`driver.${index}.has_accident`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-radio-group
                v-model="item.has_accident"
                border
                flex
                :list="yesOr2NoOption"></v-radio-group>
            </el-form-item>
          </div>
          <div
            v-if="item.has_accident==2"
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Reason</span>
              <span>原因</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.accident_reason_id`"
              :prop="`driver.${index}.accident_reason_id`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-checkbox-group
                v-model="item.accident_reason_id"
                :list="globalOptions.accident_reason"
                block
                :label-key="'name1'"
              ></v-checkbox-group>
            </el-form-item>
            <el-form-item
              v-if="item.accident_reason_id&&item.accident_reason_id.includes(4)"
              :ref="`driver.${index}.accident_reason`"
              :prop="`driver.${index}.accident_reason`"
              :show-message="false"
              :rules="rules.input"
            >
              <v-input
                v-model="item.accident_reason"
                type="textarea"
                :rows="4"
              ></v-input>
            </el-form-item>
          </div>
          <div  class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Have any been declined motor insurance, had a motor insurance policy cancelled?</span>
              <span>是否曾經被保險公司拒絕汽車保險續期或被取消汽車保險？</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.is_declined`"
              :prop="`driver.${index}.is_declined`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-radio-group
                v-model="item.is_declined"
                border
                flex
                :list="yesOrNoOption"></v-radio-group>
            </el-form-item>
          </div>
          <div
            v-if="item.is_declined==1"
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Reason</span>
              <span>原因</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.declined_reason`"
              :prop="`driver.${index}.declined_reason`"
              :show-message="false"
              :rules="rules.input"
            >
              <v-input
                v-model="item.declined_reason"
                type="textarea"
                :rows="4"
              ></v-input>
            </el-form-item>
          </div>
          <div
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Have any suffered from any physical or mental infirmity that may affect his/her ability to drive?</span>
              <span>是否健康或精神上影響閣下之駕駛能力？</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.is_no_driving_ability`"
              :prop="`driver.${index}.is_no_driving_ability`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-radio-group
                v-model="item.is_no_driving_ability"
                border
                flex
                :list="yesOrNoOption"></v-radio-group>
            </el-form-item>
          </div>
          <div
            v-if="item.is_no_driving_ability==1"
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Reason</span>
              <span>原因</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.no_driving_ability_reason`"
              :prop="`driver.${index}.no_driving_ability_reason`"
              :show-message="false"
              :rules="rules.input"
            >
              <v-input
                v-model="item.no_driving_ability_reason"
                type="textarea"
                :rows="4"
              ></v-input>
            </el-form-item>
          </div>
          <div  class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Have been prosecuted by police, Dangerous Driving or driving licence suspended or disqualified by court ?</span>
              <span>是否曾涉及不小心或魯莽駕駛、酒後或藥後駕駛、被吊銷執照或被記錄違例駕駛？</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.is_dangerous_driving`"
              :prop="`driver.${index}.is_dangerous_driving`"
              :show-message="false"
              :rules="rules.select"
            >
              <v-radio-group
                v-model="item.is_dangerous_driving"
                border
                flex
                :list="yesOrNoOption"></v-radio-group>
            </el-form-item>
          </div>
          <div
            v-if="item.is_dangerous_driving==1"
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span>Reason</span>
              <span>原因</span>
            </div>
            <el-form-item
              :ref="`driver.${index}.dangerous_driving_reason`"
              :prop="`driver.${index}.dangerous_driving_reason`"
              :show-message="false"
              :rules="rules.input"
            >
              <v-input
                v-model="item.dangerous_driving_reason"
                type="textarea"
                :rows="4"
              ></v-input>
            </el-form-item>
          </div>
          <!--          <template  v-if="!item.noshow">-->
          <!--            <div-->
          <!--              v-if="item.type!=5"-->
          <!--              class="flex-col text-left">-->
          <!--              <span :content="item.en_content">{{item.en}}</span>-->
          <!--              <span :content="item.zh_content">{{item.zh}}</span>-->
          <!--            </div>-->
          <!--            <template v-else>-->
          <!--              <el-form-item-->
          <!--                ref="driver.0.id_type"-->
          <!--                prop="driver.0.id_type"-->
          <!--                :show-message="false"-->
          <!--                :rules="rules.select"-->
          <!--              >-->
          <!--                <v-radio-group-->
          <!--                  v-model="formData.driver[0].id_type"-->
          <!--                  flex-->
          <!--                  :list="personalIdTypOptions">-->

          <!--                </v-radio-group>-->
          <!--              </el-form-item>-->
          <!--            </template>-->
          <!--            <div class="flex-col text-left color-gray">-->
          <!--              <span class="fs-12 lh-150">{{item.en_tip}}</span>-->
          <!--              <span class="fs-12 lh-150">{{item.zh_tip}}</span>-->
          <!--            </div>-->
          <!--            <el-form-item-->
          <!--              :ref="item.key=='policy_holder_is_driver'?item.key:`driver.0.${item.key}`"-->
          <!--              :show-message="showRuleMessage"-->
          <!--              :prop="item.key=='policy_holder_is_driver'?item.key:`driver.0.${item.key}`"-->
          <!--              :rules="item.key=='id_no'?rules.id_no:ruleType(item.type)"-->
          <!--            >-->
          <!--              <template v-if="item.type==1">-->
          <!--                <v-input-->
          <!--                  v-model="formData.driver[0][item.key]"-->
          <!--                  :disabled="item.disabled"-->
          <!--                  :suffixText="item.append">-->
          <!--                </v-input>-->
          <!--              </template>-->
          <!--              <template v-if="item.type==2">-->
          <!--                <v-select-->
          <!--                  v-model="formData.driver[0][item.key]"-->
          <!--                  :disabled="item.disabled"-->
          <!--                  :list="item.list"></v-select>-->
          <!--              </template>-->
          <!--              <template v-if="item.type==3">-->
          <!--                <v-radio-group-->
          <!--                  v-if="item.key=='policy_holder_is_driver'"-->
          <!--                  v-model="formData.policy_holder_is_driver"-->
          <!--                  block-->
          <!--                  border-->
          <!--                  :list="item.list"-->
          <!--                  @change="handleSameDriver"></v-radio-group>-->
          <!--                <v-radio-group-->
          <!--                  v-else-->
          <!--                  v-model="formData.driver[0][item.key]"-->
          <!--                  :disabled="item.disabled"-->
          <!--                  block-->
          <!--                  border-->
          <!--                  :list="item.list"></v-radio-group>-->
          <!--              </template>-->
          <!--              <template v-if="item.type==4">-->
          <!--                <v-birth-day-->
          <!--                  v-model="formData.driver[0][item.key]"-->
          <!--                  :disabled="item.disabled"-->
          <!--                ></v-birth-day>-->
          <!--              </template>-->
          <!--              <template v-if="item.type==5">-->
          <!--                <v-hkid-input-->
          <!--                  v-if="formData.driver[0].id_type!=2"-->
          <!--                  v-model="formData.driver[0][item.key]"-->
          <!--                ></v-hkid-input>-->
          <!--                <v-input-->
          <!--                  v-else-->
          <!--                  v-model="formData.driver[0][item.key]"-->
          <!--                  :preappend="item.preappend"-->
          <!--                  :type="item.keyType"-->
          <!--                  :suffixText="item.append">-->
          <!--                </v-input>-->
          <!--              </template>-->
          <!--            </el-form-item>-->
          <!--          </template>-->
        </div>
        <v-button @click="handleNext">下一步 Next</v-button>
      </div>
    </el-form>
  </section>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import MixinsPolicyForm from '@/mixins/policyForm'
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import IDCheck from '@/utils/idcheck'
export default {
  name: 'driverForm',
  mixins:[MixinsOptions,MixinRules,MixinsPolicyForm],
  data() {
    const checkName = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { last_name,first_name } = this.formData.driver[index]
      if(!last_name&&!first_name) {
        callback(new Error('Fill in at least one of the content 最少填寫一項內容'))
      }
      callback()
    }
    const checkIdNo = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      let {client_type} = this.quoteInformationForm||{}
      const { id_no,id_type } = this.formData.driver[0]
      if( value&&!IDCheck(value)&&!this.isCompany) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      if(!id_no&&this.isCompany)
      {
        callback(new Error('Please enter 請輸入'))
      }
      // else {
      //   if(!id_no && !value) {
      //     callback(new Error('請輸入正確的身份证号码'))
      //   }
      // }
      callback()
    }
    return {
      nextPath:'/quoting/insure/motor/form',
      isFirstName:true,
      formData:{
        // policy_holder_is_driver:1,
        number_of_drivers:1,
        effective_date:'',
        driver:[
          {
            first_name:'',
            last_name:'',
            title:'',
            occ_id:'',
            occ:'',
            birth:'',
            id_no:'',
            id_type:'',
            relationship_id:'',
            driving_licence_id:'',
            driver_experience_id:'',
            accidents:'',
            is_deducted_point:'',
            deducted_points:'',
            deducted_point_reason_id:[],
            deducted_point_reason:'',
            accident_reason_id:[],
            has_accident:'',
            accident_reason:'',// 意外事故原因

            accidents_file_id:['','',''],// 口供紙
            accidents_file:[{},{},{}],// 口供紙
            id_file_id:'',// 证件文件 HKID/PASSPORT文件
            id_file:'',// 证件文件 HKID/PASSPORT文件
            is_declined:'',// 是否被保險公司拒絕或取消保險 1-yes 2-no
            declined_reason:'',// 被保險公司拒絕或取消保險原因
            is_no_driving_ability:'',// 是否被健康或精神上影響駕駛能力 1-yes 2-no
            no_driving_ability_reason:'',// 被健康或精神上影響駕駛能力原因
            is_dangerous_driving:'',// 是否有危險駕駛記錄 1-yes 2-no
            dangerous_driving_reason:'',// 有危險駕駛記錄原因
            dangerous_driving_file_id:'',// 法庭信
            dangerous_driving_file:{},// 法庭信

            driving_licence_file_id:'',
            driving_licence_file:{},

            mec_file_id:'',
            mec_file:{},
          }
        ],
      },
      rules: {
        id_no: [
          { validator: checkIdNo, trigger: ['blur','change'] }
        ],
        first_name: [
          { validator: checkName, trigger: ['change'] }
        ],
        last_name: [
          { validator: checkName, trigger: ['change'] }
        ],
        driver_name: [
          { validator: checkName, trigger: ['blur','change'] }
        ],
      },
    }
  },
  computed:{
    driver_name(){
      let {driver} = this.formData||{}
      if(driver.length>1)
      {
        let {last_name,first_name} = driver[1]||{}
        return last_name||first_name
      }
      return true
    },
    first_driver_name(){
      let {driver} = this.formData||{}
      if(driver.length>0)
      {
        let {last_name,first_name} = driver[0]||{}
        return last_name||first_name
      }
      return true
    },
    dirverExpList(){
      let {class_id=1003} = this.quoteInformationForm||{}
      let {driving_experience} = this.globalOptions||{}
      let arr = driving_experience.filter(item=>item.class_id==class_id)
      return arr.length>0?arr[0].item||[]:[]
    },
    isCompany(){
      let {client_type} = this.quoteInformationForm||{}
      return client_type==2
    },

    driver_obj(){
      return {
        first_name:'',
        last_name:'',
        title:'',
        occ_id:'',
        occ:'',
        birth:'',
        id_no:'',
        id_type:'',
        relationship_id:'',
        driving_licence_id:'',
        driving_licence_file:{},
        driver_experience_id:'',
        has_accident:'',
        accidents:'',
        is_deducted_point:'',
        deducted_points:'',
        deducted_point_reason_id:[],
        deducted_point_reason:'',
        accident_reason:'',// 意外事故原因
        accident_reason_id:[],
        accidents_file_id:['','',''],// 口供紙
        accidents_file:[{},{},{}],// 口供紙
        id_file_id:'',// 证件文件 HKID/PASSPORT文件
        is_declined:'',// 是否被保險公司拒絕或取消保險 1-yes 2-no
        declined_reason:'',// 被保險公司拒絕或取消保險原因
        is_no_driving_ability:'',// 是否被健康或精神上影響駕駛能力 1-yes 2-no
        no_driving_ability_reason:'',// 被健康或精神上影響駕駛能力原因
        is_dangerous_driving:'',// 是否有危險駕駛記錄 1-yes 2-no
        dangerous_driving_reason:'',// 有危險駕駛記錄原因
        dangerous_driving_file_id:'',// 法庭信
        dangerous_driving_file:{},// 法庭信

        mec_file_id:'',
        mec_file:{},
      }
    }
  },
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        let {driver=[],policy_holder_is_driver=1,effective_date} = val||{}
        if(driver.length<=0){
          this.formData.driver =[
            {
              ...this.driver_obj,
            }
          ]
        }else this.formData.driver = driver
        this.formData.number_of_drivers = val.number_of_drivers
        this.formData.effective_date = effective_date
        this.handleChangeDriver()
        this.$nextTick(()=>{
          this.handleSameDriver()
        })
        // Object.keys(this.formData).some(key=>{
        //   if(val&&val[key]){
        //     this.formData[key] = val[key]
        //   }
        // })
      }
    },
    // isOneOfDriver:{
    //   immediate:true,
    //   handler(val){
    //     console.log('val--',val)
    //     this.$nextTick(()=>{
    //       this.handleSameDriver()
    //     })
    //   }
    // }
    driver_name:{
      immediate:true,
      handler(val){
        this.$nextTick(()=>{
          let {driver} = this.formData
          if(driver.length<2)return
          if(this.$refs.form){
            if(!val&&!this.isFirstName){
              this.$refs.form.validateField(['driver.1.last_name','driver.1.first_name'])

            }
            else  {
              this.$refs.form.clearValidate(['driver.1.last_name','driver.1.first_name'])
            }
            this.isFirstName = false
          }
        })
      }
    },
    first_driver_name:{
      immediate:true,
      handler(val){
        this.$nextTick(()=>{
          if(this.$refs.form){
            if(!val&&!this.isName){
              this.$refs.form.validateField(['driver.0.last_name','driver.0.first_name'])
            }
            else  {
              this.$refs.form.clearValidate(['driver.0.last_name','driver.0.first_name'])
            }
            this.isName = false
          }
        })
      }
    },
  },
  mounted() {

  },
  methods:{
    handleChangeDriver(){
      let driver_obj =JSON.parse(JSON.stringify(this.driver_obj))
      let {number_of_drivers} =this.formData||{}
      let driver_len = this.formData.driver.length
      if(driver_len<number_of_drivers)
      {
        for(var i=driver_len;i<number_of_drivers;i++)
          this.formData.driver.push(driver_obj)
        this.isFirstName = true
        this.isName = true
      }else this.formData.driver.splice(number_of_drivers,driver_len-number_of_drivers)
    },
    handleSameDriver(){
      let {policy_holder_occ,policy_holder_first_name,policy_holder_last_name,policy_holder_pp_no,policy_holder_title,policy_holder_id_no,policy_holder_id_type,policy_holder_occ_id,policy_holder_birth} = this.quoteInformationForm||{}
      let {policy_holder_is_driver}= this.formData||{}
      if(this.isOneOfDriver)
      {
        let params ={
          first_name:policy_holder_first_name,
          last_name:policy_holder_last_name,
          title:policy_holder_title,
          occ_id:policy_holder_occ_id,
          occ:policy_holder_occ,
          birth:policy_holder_birth,
          id_no:policy_holder_id_no,
          pp_no:policy_holder_pp_no,
          id_type:policy_holder_id_type,
          relationship_id:8
        }
        this.$set(this.formData.driver,0,{
          ...this.formData.driver[0],
          ...params
        })

      }
      // this.setQuoteInformationForm({
      //   ...this.quoteInformationForm,
      //   driver
      // })
    }
  }
}
</script>

<style scoped lang="scss">
  .dirver-line{
    padding-top: 25px;
    border-top: 1px solid #B4B4B7;
    .driver-text-title{
      margin-bottom: 25px;
      span{
        color: #1A1A1C;
        font-feature-settings: 'pwid' on;
        /* H3 */
        font-family: Noto Sans HK;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 39px */
        letter-spacing: 0.52px;
        &:first-child{
          font-size: 20px;
          letter-spacing: 0.4px;
        }
      }
    }
  }
</style>
